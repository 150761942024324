import { AuthContext } from "core/context";
import React, { useContext } from "react"
import { Navigate, useLocation, useParams } from "react-router-dom";
import { isRolesPermitted } from "core/utils/common"; 
import AppLoading from 'core/components/appLoading';
import { buildBreadcrumbs } from "./breadcrumbs";
import { ROUTES } from "routes/constants";
// import { isValidBusinessId } from "utils";
// import LicenseError from "core/pages/licenseError";
// import { BlankPage } from "core/layouts";
// import VerificationError from "core/pages/VerificationError";
// import RenderBusinessPage from "core/components/renderBusinessPage";

const AuthenticatedRoute = ({ children, rolesAssigned, routes, path, route}) => {
  
  const { isUserLoggedIn, permissions=[] } = useContext(AuthContext);
  // const { businesses } = useContext(AppContext);
  const { businessId } = useParams();

  const location = useLocation();

  if(!isUserLoggedIn) { 
    return <AppLoading businessId={businessId} /> 
  }

  const breadcrumbs = buildBreadcrumbs(routes, path, location);

  // After user login validate path businessId value with app api returned businesses 
  // if(isUserLoggedIn) { 

    // const businessFound = isValidBusinessId(businesses, businessId);
    // if(!businessFound)return <Navigate to='/error/' replace={true} />;

    // // Pass verification required component 
    // if(route.verificationRequired && !(businessFound?.isVerified)){
    //   return <BlankPage {...route.pageLayoutProps}><VerificationError /></BlankPage>
    // }

    // // Pass license component instead of selected component
    // if(route.licenseRequired && !(businessFound?.licenceExpiryDaysCount > 0)){
    //   return <BlankPage {...route.pageLayoutProps}><LicenseError /></BlankPage>
    // }

    // redirec to unauthorized page id role is invlaid
    if(rolesAssigned?.length > 0 && !(isRolesPermitted(rolesAssigned, permissions))){
      return <Navigate to={`${ROUTES.UNAUTHORIZED}`} replace={true} />
    }

    // return <RenderBusinessPage breadcrumbs={breadcrumbs} businessId={businessId}>{children}</RenderBusinessPage>
  // }
  // redirec to unauthorized page id role is invlaid
  // if(rolesAssigned?.length > 0 && !(isRolesPermitted(rolesAssigned, permissions))){
  //   return <Navigate to={`${ROUTES.UNAUTHORIZED}`} replace={true} />
  // }

  return React.cloneElement(children, {...{breadcrumbs}});
  
};

export default AuthenticatedRoute;
