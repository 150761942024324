import { ROUTES } from './constants';

const NavbarMenuItems = [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    roles: [],
    url: ROUTES.DASHBOARD,
    // licenseRequired: true
  },
  {
    name: 'Employees',
    icon: 'supervised_user_circle',
    roles: [],
    url: ROUTES.EMPLOYEES,
    // licenseRequired: true
  },
  {
    name: 'Resellers',
    icon: 'shield_person',
    roles: ['Admin'],
    url: ROUTES.RESELLERS,
    // licenseRequired: true
  },
  {
    name: 'Users',
    icon: 'groups',
    roles: [],
    url: ROUTES.USERS,
    // licenseRequired: true
  },
  {
    name: 'Properties',
    icon: 'store',
    roles: [],
    url: ROUTES.MANAGE_PROPERTY,
    // licenseRequired: true
  },
  // {
  //   name: 'Operations',
  //   subHeading: true,
  // },
  {
    name: 'Reports',
    icon: 'description',
    group: true,
    roles: [],
    // licenseRequired: true
    items: [
      {
        name: 'Users',
        icon: 'groups',
        url: ROUTES.USER_REPORT
      },
      {
        name: 'Properties',
        icon: 'store',
        url: ROUTES.PROPERTY_REPORT
      },
    ]
  },
];

export default NavbarMenuItems;
