import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import { AppContextProvider, AuthContextProvider } from 'core/context/';
import {  IconButton, Grid } from '@mui/material';
import ConfirmDialogProvider from 'core/context/ConfirmDialogProvider';
import { AppLayout, AppPublicLayout } from 'core/layouts';
import './App.scss';
import { CancelRounded } from '@mui/icons-material';
// import Grow from 'core/components/snackbar/GrowTransition';

// import AppLoading from 'core/components/appLoading';
// import Hidden from 'Hidden';

function App({children}) {

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => { 
      notistackRef.current.closeSnackbar(key);
  }

  return (
    <AppContextProvider>
      <AuthContextProvider>
        <SnackbarProvider 
          hideIconVariant
          // TransitionComponent={Grow}
          maxSnack={3} 
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{maxWidth: 480}}
          preventDuplicate
          ref={notistackRef}
          autoHideDuration={3000}
          action={(key) => (
            // <Button onClick={onClickDismiss(key)} size="small" sx={{color: "#fff"}}>
            //   Dismiss
            // </Button>
            <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5, color: "#fff" }}
            onClick={onClickDismiss(key)}
          >
            <CancelRounded />
          </IconButton>
          )}
        >
          <ConfirmDialogProvider>
              {children}
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </AuthContextProvider>
    </AppContextProvider>
  );
}

export default React.memo(App);

export const AuthLayout = React.memo(function() {
  return (
    <App>
      <AppLayout />
    </App>
  )
});

export const PublicLayout = React.memo(function() {
  return (
    <App>
      <Suspense
      fallback={
        <Grid container component="main"
          sx={{ 
            minHeight: '100vh',
            backgroundImage: `url(assets/images/page_bg.jpg)`,
            backgroundPosition: 'center', 
            backgroundSize: 'cover',
          }}
          flexDirection={{md:'row'}}
          justifyContent="center"
          alignItems="center"
        />
      }
    >
      <AppPublicLayout />
    </Suspense>
  </App>
);
});
