import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from './constants';
import { BlankPage, BlankWithPageTitle } from "core/layouts";
// import { BlankWithPageTitleV1 } from "core/layouts/pages/BlankWithPageTitleV1";
// import RoutePage from "pages/RoutePage";

// Lazy imports 
const EmployeesPage = lazy(() => import('pages/employees'));
const UsersPage = lazy(() => import('pages/users'));
const DashboardPage = lazy(() => import('pages/dashboard'));
const UserReportPage = lazy(() => import('pages/reports/UserReport'));
const PropertiesReportPage = lazy(() => import('pages/reports/PropertiesReport'));
const ManagerPropertyPage = lazy(() => import('pages/manageProperty'));
const ResellerPage = lazy(() => import('pages/resellers'));

// const CompleteBusinessProfile = lazy(() => import('pages/addBusinessCenter/completeProfile'));
// const PaytmPaymentGatewayChargesPage = lazy(() => import('pages/paytm-gateway-fee'));

const AppRoutes = [
  {
    path: "/",
    exact: true,
    hideInBreadcrumb: true,
    pageLayout: BlankPage,
    component: () => <Navigate to={ROUTES.DASHBOARD} replace={true} />
  },
  {
    name: 'Dashboard',
    path: ROUTES.DASHBOARD,
    pageLayout: BlankPage,
    component: DashboardPage,
    enableAuth: true,
    pageLayoutProps: {
      // title: 'Dashboard',
      // subTitle: 'stati, etc...',
    },
  },
  {
    name: 'Resellers',
    path: ROUTES.RESELLERS,
    pageLayout: BlankWithPageTitle,
    component: ResellerPage,
    enableAuth: true,
    licenseRequired: true,
    roles: ['Admin'],
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Resellers',
      // subTitle: 'Add/Delete/Manage Partners, Managers, Staff of your property',
    },
  },
  {
    name: 'Employees',
    path: ROUTES.EMPLOYEES,
    pageLayout: BlankWithPageTitle,
    component: EmployeesPage,
    enableAuth: true,
    licenseRequired: true,
    roles: [],
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Employees',
      // subTitle: 'Add/Delete/Manage Partners, Managers, Staff of your property',
    },
  },
  {
    name: 'Users',
    path: ROUTES.USERS,
    pageLayout: BlankWithPageTitle,
    component: UsersPage,
    enableAuth: true,
    licenseRequired: true,
    roles: [],
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Users',
      // subTitle: 'Add/Delete/Manage Partners, Managers, Staff of your property',
    },
  },
  {
    name: 'Property',
    path: ROUTES.MANAGE_PROPERTY,
    pageLayout: BlankWithPageTitle,
    component: ManagerPropertyPage,
    enableAuth: true,
    licenseRequired: true,
    roles: [],
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Manage Property',
      // subTitle: 'Add/Delete/Manage Partners, Managers, Staff of your property',
    },
  },
  {
    name: 'PropertyReport',
    path: ROUTES.PROPERTY_REPORT,
    pageLayout: BlankWithPageTitle,
    component: PropertiesReportPage,
    enableAuth: true,
    licenseRequired: true,
    roles: [],
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'Property Report',
      // subTitle: 'Add/Delete/Manage Partners, Managers, Staff of your property',
    },
  },
  {
    name: 'UserReports',
    path: ROUTES.USER_REPORT,
    pageLayout: BlankWithPageTitle,
    component: UserReportPage,
    enableAuth: true,
    licenseRequired: true,
    roles: [],
    pageLayoutProps: {
      hideBreadcrumb: true,
      title: 'User Reports',
      // subTitle: 'Add/Delete/Manage Partners, Managers, Staff of your property',
    },
  },

];
export default AppRoutes;
