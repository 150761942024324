import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

export const GOOGLE_MAP_API = process.env.REACT_APP_GMAP_API;

export const profilesMenuItems=[
  {icon: FacebookIcon, name: 'Facebook', color:'#3b5998'},
  {icon: InstagramIcon, name: 'Instagram', color:'#3f729b'},
  {icon: TwitterIcon, name: 'Twitter', color:'#55acee'}, 
  {icon: YouTubeIcon, name: 'YouTube', color:'#cd201f'}, 
  {icon: LinkedInIcon, name: 'LinkedIn', color:'#0077b5'},
  {icon: PinterestIcon, name: 'Pinterest', color:'#bd081c'}
];

export function socailProfileWitIcon() {
  const data={}
  profilesMenuItems.forEach(profile => {
    data[profile.name] = profile;
  })
  return data;
}

// Payment Providers
export const PAYMENT_GATEWAY = {
  PAYTM: 'paytm',
  RAZORPAY: 'razorPay'
}

export const paymentModes = [
  { name: 'Card', value: 'CARD'},
  { name: 'Cash', value: 'CASH'},
  { name: 'Cheque', value: 'CHEQUE'},
  { name: 'NEFT/IMPS', value: 'NEFT_IMPS'},
  { name: 'UPI', value: 'UPI'},
]

export const mainPaymentTypes = [
  { name: 'Rent', value: 'RENT'},
  { name: 'Advance', value: 'ADVANCE'},
  { name: 'Maintenance', value: 'MAINTENANCE'},
];

export const paymentTypes = [
  { name: 'Other Charges', value: 'OTHER_CHARGES'},
  { name: 'Other Additional Charges', value: 'ADDITIONAL_OTHER'},
  { name: 'Electricity', value: 'ELECTRICITY'},
  { name: 'Water', value: 'WATER'},
  { name: 'Additional Maintenance', value: 'ADDITIONAL_MAINTENANCE'},
  { name: 'Food', value: 'FOOD'},
  { name: 'Fine', value: 'FINE'},
  { name: 'AC', value: 'AC'},
  { name: 'Gas', value: 'GAS'},
  { name: 'Parking', value: 'PARKING'},
  { name: 'Housekeeping', value: 'HOUSEKEEPING'},
  { name: 'Cable', value: 'CABLE'},
  { name: 'Internet', value: 'INTERNET'},
  { name: 'Additional Rent', value: 'ADDITIONAL_RENT'},
];

let _paymentTypesObj = {};
[...paymentTypes, ...mainPaymentTypes].forEach(mode => {
  _paymentTypesObj[mode.value] = mode.name;
});
export const paymentTypesObj = _paymentTypesObj;