import React from 'react';
import { 
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Icon,
} from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const NavListItem = ({menuItem, sx = {}, onNavLinkClick}) => {

  const  {name, icon, url} = menuItem;

  /** Hooks */
  const  { pathname } = useLocation();

  return (
    <ListItemButton component={NavLink} to={url} key={name}  selected={pathname === url} sx={{ py:1.5, px: 2.3, ...sx }} onClick={onNavLinkClick}>
      <ListItemIcon>
        {/* <Icon className='material-icons material-icons-round'>{icon}</Icon> */}
        <Icon className='material-icons material-symbols-rounded'>{icon}</Icon>

      </ListItemIcon>
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          fontSize: 14,
          lineHeight: '16px',
        }}
      />
    </ListItemButton>
  );
}
 
export default React.memo(NavListItem);
