import { httpService } from 'core/services/httpService';

const registerUser = ({body, otp}) => {
  return httpService.POST({url: `/user/register/${otp}`, body});
}

async function getAppState(options={}) {
  return httpService.GET({url: `/reseller/profile`}, {backgroundRequest: true, ...options});
}

const sendSignUpOTP = ({data = {}}) => {
  const { mobile } = data;
  return httpService.GET({url:`/user/${mobile}/sendOtp`});
}

async function signInUser({body, signal}) {
  return httpService.POST({url: '/authenticate', body}, {signal});
};

// async function logoutUser() {
//   return httpService.get({url: '/logout'});
// };

async function sendPasswordResetOTP({mobile}) {
  return httpService.GET({url: `/user/${mobile}/sendPasswordResetOtp` });
};

async function validateOTP(params) {
  return httpService.GET({url: `/user/validateOtp`, params});
};

async function resetPassword(mobile){
  return httpService.GET({url: `/reseller/${mobile}/resetPassword`,});
};

async function getNewAccessToken({signal}){
  return httpService.GET({url: '/refreshSession',}, {signal, backgroundRequest: true});
};

// const DeleteUserInfoInBrowser = () => {
//   window.sessionStorage.removeItem('rpgmUserInfo');
//   window.sessionStorage.removeItem('pgc$token');
// }

function loader(){
  return {abc: true};
}

export { getAppState, signInUser, resetPassword, validateOTP, sendPasswordResetOTP, sendSignUpOTP, registerUser, getNewAccessToken , loader };
