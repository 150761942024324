import { lazy } from 'react';
import Login from "core/pages/login";
import AppRoutes from "routes";
import { ROUTES } from 'core/utils/constants';
import { BlankWithPageTitle, BlankPage, BlankPublicPage } from "core/layouts";

const Profile = lazy(() => import('core/pages/Profile/index'));
const ForgotPassword = lazy(() => import('core/pages/forgot-password'));
const ServerDown = lazy(() => import('core/pages/504'));
const UnAuthorized = lazy(() => import('core/pages/403'));
const MyAccount = lazy(() => import('core/pages/account'));
// const PrivacyPage = lazy(() => import('core/pages/privacy'));
// const TermsOfUsePage = lazy(() => import('core/pages/terms'));
// const ContactUsPage = lazy(() => import('core/pages/contact'));
// const AboutUsPage = lazy(() => import('core/pages/about'));
// const SubscriptionsPage = lazy(() => import('core/pages/subscriptions'));

const Routes = [
  {
    path: ROUTES.UNAUTHORIZED,
    pageLayout: BlankPage,
    component: UnAuthorized,
    enableAuth: true,
  },
  {
    path: ROUTES.SERVER_DOWN,
    component: ServerDown,
    pageLayout: BlankPublicPage,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
    pageLayout: BlankPublicPage,
  },
  // {
  //   path: ROUTES.REGISTER,
  //   component: Login,
  //   pageLayout: BlankPublicPage,
  // },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPassword,
    pageLayout: BlankPublicPage,
  },
  // {
  //   path: ROUTES.EMAIL_UNSUBSCRIPTION,
  //   component: SubscriptionsPage,
  //   pageLayout: BlankPublicPage,
  // },
  {
    name: "Profile",
    path: ROUTES.PROFILE,
    pageLayout: BlankWithPageTitle,
    component: Profile,
    enableAuth: true,
    // loader: profileLoader,
    pageLayoutProps: {
      title: 'Profile',
      subTitle: 'Update your profile info',
    },
  },
  {
    name: 'Account',
    path: ROUTES.MY_ACCOUNT,
    pageLayout: BlankWithPageTitle,
    component: MyAccount,
    enableAuth: true,
    pageLayoutProps: {
      title: 'My Account',
      subTitle: 'Profile, Password, Wallet etc...',
    },
    // hideInBreadcrumb: true
  },
  ...AppRoutes
];
export default Routes;
