import React, { useState, useContext, useEffect } from 'react';  
import { Grid, Paper, Box, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { AppContext, AuthContext } from 'core/context';
import { useLocation } from 'react-router-dom';
import SignIn from './SignIn';
import { logger } from 'core/utils/logger';
// import CircleIcon from '@mui/icons-material/Circle';

export default function LoginPage() {
  logger.info('LoginPage mounting...');
  /* Hooks */
  const [toggleSignIn, setToggleSignIn] = useState(null);
  let navigate = useNavigate();
  const {isUserLoggedIn, resetContext: resetAuthContext} = useContext(AuthContext);
  const { resetContext: resetAppConext} = useContext(AppContext);
  const location = useLocation()
  
  useEffect(() => {
    logger.debug('LoginPage mounted');

    if(isUserLoggedIn){
      setTimeout(()=>navigate('/'), 100);
    }else{
      setToggleSignIn(true);
      resetAuthContext();
      resetAppConext();
    }
  }, [navigate, location, resetAuthContext, isUserLoggedIn, resetAppConext]);

  return typeof toggleSignIn === 'boolean' ? (
    <Grid container component="main"
      sx={{ 
        minHeight: '100vh',
        backgroundColor: '#bc003b',
        width: '100%',
        backgroundImage: `url(assets/images/bg-pattern.png)`,
        backgroundPosition: 'center', 
        backgroundSize: 'cover',
      }}
      flexDirection={{xs: 'column', md:'row'}}
      justifyContent="center"
      alignItems="center"
      p={{xs: '2rem', md: '3rem'}}
      xs={12}
      item
    >
      <Grid container item xs={12} justifyContent="center">
        <Grid item  xs={12} p={1}  textAlign="center" alignItems="center">
          <Typography variant='h6' gutterBottom color="#fff">PG Manager Reseller Console</Typography>
        </Grid>
        <Grid borderRadius={3} component={Paper} item xs={12} sm={8.5} md={6} lg={4} xl={3.5}  textAlign="center" sx={{p:4, maxWidth: '520px !important'}} display="flex" flexDirection="column" justifyContent="space-between">
          <Box my={2}>
            <Box
              style={{ 
                width: '100px',
                display: 'block',
                textAlign: 'center',
                position: 'relative',
                margin: '0 auto 1rem',
              }}
            >
              <img
                style={{ 
                  width: '80%',
                }}
                alt="PG Manager Logo" src={`assets/images/logo-color.png`}
                className='login-logo'
              />
               PG Manager™
            </Box>
            <SignIn />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
}
