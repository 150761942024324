import React, { useMemo, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, Container, LinearProgress, Backdrop, } from '@mui/material';
import Header from 'core/components/header';
import ErrorBoundary from 'core/pages/error';
// import TokenRefresh from 'core/components/tokenRefresh';
import appTheme from 'core/theme';
import NavDrawer from 'core/components/drawer';
import Loader from 'core/components/Loader';
import { Outlet, useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppContext } from 'core/context';
import { useTheme } from '@mui/material/styles';
import { logger } from 'core/utils/logger';
import { menuItems } from 'core/components/drawer';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const AppLayout = ({navbar=true }) => {
  logger.info('App layout mounting...');
  
  const hideNavIcon = useMemo(() => menuItems.length === 0 ? false : navbar , [navbar]);
  const [showBackdrop] = useState(false);
  const { updateAppContext } = useContext(AppContext);
  const theme  = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'));
  const { businessId } = useParams();

  const handleClose = useCallback(() => {
    if(matches){
      updateAppContext({isSideDrawerOpen: false});
    }
  },[matches, updateAppContext]);

  // useEffect(() => {
  //   if(matches && isSideDrawerOpen){
  //     setShowBackdrop(isSideDrawerOpen)
  //   }else{
  //     setShowBackdrop(false)
  //   }
  // }, [isSideDrawerOpen, matches, updateAppContext]);

  useEffect(() => {
    logger.debug('App layout mounted');
    return () => {
    }
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <Box 
        sx={{ 
          minHeight: '100vh', 
          // backgroundColor: appTheme.palette.primary.lighter,
          // backgroundImage: `url(${process.env.REACT_APP_PATH}/assets/images/page_bg.jpg)`,
          // backgroundPosition: 'center', 
          // backgroundSize: 'cover',
          display: 'flex'
        }}
      >
        <CssBaseline />
        <Loader />
        {navbar && <NavDrawer />}
        <Header hideNavIcon={!hideNavIcon} />
        <Box 
          component="main" 
          sx={{ 
            py: 2, 
            // mt: {xs: `${isSideDrawerOpen ? '102px' : 0}`, sm: `${isSideDrawerOpen ? '72px' : 0}`},
            // pt: {xs: `${isSideDrawerOpen ? 0 : '102px'}`, sm: `${isSideDrawerOpen ? 0 : '72px'}`}, 
            // pt: '72px',
            pt: {xs: businessId ? '120px' : '72px', sm: '72px'},
            flexGrow: 1, 
            // maxHeight: {xs: `${isSideDrawerOpen ? '100vh' : '100%'}`, sm: `${isSideDrawerOpen ? '100vh' : '100%' }`}, 
            // overflow: matches && isSideDrawerOpen ? 'hidden' : 'auto'
            maxHeight: '100vh',
            overflow: 'hidden'
          }}
        >
          <SimpleBar style={{height: '100%'}} tabIndex="-1">
            <Container maxWidth="xl" >
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={showBackdrop}
                onClick={handleClose}
              />
              <ErrorBoundary>
                <Suspense fallback={<Box sx={{ width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 999999 }}>
                  <LinearProgress color="tertiary" sx={{height: 4}} /></Box>}>
                  {/* <TokenRefresh /> */}
                  <Outlet />
                </Suspense>
              </ErrorBoundary>
            </Container>
          </SimpleBar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
 
export default React.memo(AppLayout);
