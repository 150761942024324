import { ROUTES as CORE_ROUTES} from 'core/utils/constants';

export const pathPrefix = '';

export const ROUTES = {
  ...CORE_ROUTES,
  DASHBOARD: `/dashboard/`,
  EMPLOYEES: `/employees/`,
  RESELLERS: `/resellers/`,
  USERS: `/users/`,
  MANAGE_PROPERTY: `/properties/`,
  USER_REPORT: `/reports/user/`,
  PROPERTY_REPORT: `/reports/property/`,
}
