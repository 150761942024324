import React, { useCallback, useContext, useEffect } from 'react';
import { Avatar, Box, CircularProgress, CssBaseline, Typography } from '@mui/material';
import { getAppState } from 'core/services/auth';
import { AuthContext } from "core/context";

const AppLoading = () => {

  const { updateAuthContext } = useContext(AuthContext);

  const getAppLoginStatus = useCallback((signal) => {

    getAppState({signal}).then(({response={}, error, statusCode}) => {
      if(error?.message === 'canceled') return;
      if(statusCode !==  200) return;
      setTimeout(async() => {
        const { email, firstName='', lastName='', role, id: uuid, mobile, resellerEntity } = response;
        const name = `${firstName} ${lastName}`;
        const authData = {data: { name, email, role, uuid, mobile, resellerEntity }, userId: uuid, permissions: [role]};
        updateAuthContext({isUserLoggedIn: true, ...authData});
        // return;
      }, 300);
    })
  }, [updateAuthContext]);
  
  useEffect(() => {
    const controller = new AbortController();
    getAppLoginStatus(controller.signal);
    return () => {
      controller.abort();
    }
  }, [getAppLoginStatus]);
  

  return (
    <Box sx={{ minHeight: 'calc(100vh - 100px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CssBaseline />
      <Box sx={{ position: 'relative' }}>
        <Avatar
          alt="PG Manager Loading..."
          src="assets/images/favicon.png"
          sx={{ width: 40, height: 40 }}
        />
        <CircularProgress
          color="primary"
            size={68}
            sx={{
              color: '#0e1d34',
              position: 'absolute',
              top: -14,
              left: -14,
              zIndex: 1,
            }}
          />
          <Typography sx={{mt: 2.4, ml: '-12px'}}>Loading...</Typography>
      </Box>
    </Box>
  );
}
 
export default React.memo(AppLoading);
