import React from 'react';
import {
 ListItemButton,
 ListItemIcon,
 Icon,
 ListItemText,
 Collapse,
 Divider
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NavList from './NavList';

const CollapseableNavList = ({ menuItem, onNavLinkClick }) => {

    const  {name, icon, items } = menuItem;
    const menuUrlsArray = items.map(({url}) => url);
    
    const  { pathname } = useLocation();
    const [open, setOpen] = React.useState( menuUrlsArray.includes(pathname) || false);

    const handleClick = () => {
        setOpen(!open);
    };
    
    return(
        <>
            <ListItemButton onClick={handleClick} sx={{ my: 0, pb: 1}} >
                <ListItemIcon>
                    <Icon className='material-icons material-symbols-rounded'>{icon}</Icon>
                </ListItemIcon>
                <ListItemText
                 primary={name}
                    primaryTypographyProps={{
                        fontSize: 14,
                        lineHeight: '18px',
                        color: '#000',
                      }}
                />
                {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider sx={{my: 0.5, mx: 1, borderColor: '#ccc'}}/>
                <NavList menuItems={items} sx={{pl: 2.4}} onNavLinkClick={onNavLinkClick} />
            </Collapse>
        </>
    );
};
 
export default CollapseableNavList;
