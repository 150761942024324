import { BROWSER_STORAGE } from "core/utils/constants";

class AuthService {
  #authToken;
  #userId;
  #userRoles;
  constructor() {
    this.#authToken = null
  }

  getAuthorizationToken() {
    return this.#authToken || sessionStorage.getItem(BROWSER_STORAGE.SESSION_AUTH) || '';
  }

  parseJwt(token){
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  get userRoles() {
    return this.#userRoles;
  }

  set userRoles(roles) {
    this.#userRoles = roles;
  }

  setAuthToken(token) {
    this.#authToken = token;
    window.sessionStorage.setItem(BROWSER_STORAGE.SESSION_AUTH, token);

    const { sub } = this.parseJwt(token);
    this.setUserId(sub);
    // console.log('decodedToken', decodedToken);
  }

  getUserIdFromBrowser() {
    return window.sessionStorage.getItem(BROWSER_STORAGE.SESSION_USER_ID) || null;
  }

  setUserId(id) {
    this.#userId = id;
    window.sessionStorage.setItem(BROWSER_STORAGE.SESSION_USER_ID, id);
  }

  getUserId() {
    return this.#userId || this.getUserIdFromBrowser();
  }

  clearData() {
    this.#authToken=null;;
    this.#userId=null;
    window.sessionStorage.removeItem(BROWSER_STORAGE.SESSION_USER_ID);
    window.sessionStorage.removeItem(BROWSER_STORAGE.SESSION_AUTH);
  }
}
const authService = new AuthService()
export default authService;
